<template>
    <section class="main">
      <PageTitle title="Kontakta oss" />
      <section class="dark red">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <h2>Kontaktuppgifter och öppettider.</h2>
              <p>I formuläret nedanför har du möjlighet att kontakta oss via mail. Vi försöker besvara mailen så fort som möjligt.<br>Om du hellre vill kontakta oss via telefon, eller besöka oss, så hittar du all kontaktinformation till höger om kontaktformuläret.</p>
            </div>
          </div>
        </div>
      </section>
      <section class="content-area light"> 
        <div class="container">
          <div class="row">
            <div class="col-sm-8 col-md-8">
              <ContactForm />
            </div>
            <div class="col-sm-4 col-md-4 margin-top">
              <div class="row">
                <div class="col-lg-12">
                  <OpeningHours />
                </div>
                <div class="col-lg-12">
                  <h2 class="underline">Kontaktuppgifter</h2>
                  <p><span class="fa fa-map-marker"></span>Kungsgatan 16, 461 31, Trollhättan.</p>
                  <p><span class="fa fa-phone"></span>0520-10671 / 0733166915</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
</template>

<script>
import { PageTitle, ContactForm, OpeningHours } from '@/components'

export default {
  components: {
    PageTitle,
    ContactForm,
    OpeningHours,
  },
  metaInfo: {
    title: 'Kontakta oss | Bageri & Konditori i Trollhättan | Dagmar Carlssons Hembageri Eftr. | dagmarcarlssons.se',
    description: 'Om du har några frågor så kan du när som helst kontakta oss på Dagmar Carlssons Hembageri Eftr.',
  },
}
</script>

<style></style>
